<template>
  <b-overlay :show="showOverlay">

    <!--    Editor Modal  -->
    <b-modal
        id="modal-Editor"
        centered
        title="افزودن کد html"
        ok-title="ثبت"
        ok-only
        @ok.preventDefault()="customButtonClick"
    >
      <b-row>
        <span class="mx-1 mb-1">کد HTML خود را در اینجا قرار دهید</span>
        <b-col cols="12">
          <textarea class="form-control" v-model="rawHTML"></textarea>
        </b-col>

      </b-row>
    </b-modal>

    <b-modal
        id="modal-delete"
        centered
        ok-title="بله"
        cancelTitle="خیر"
        @ok="deleteMedia"
    >
      <span>آیا از حذف کردن این عکس اطمینان دارید ؟ </span>
    </b-modal>
    <b-card>
      <b-row>
        <b-col class="my-1" cols="12">
          <h2>اطلاعات پایه فروشگاه</h2>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group
              label="نام فروشگاه"
          >
            <b-form-input
                v-model="shopInfo.companyName"
                placeholder="نام فروشگاه"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="8">
          <b-form-group
              label="توضیحات کوتاه فروشگاه"
          >
            <b-form-input
                v-model="shopInfo.summary"
                placeholder="توضیحات کوتاه فروشگاه"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col cols="12" class="my-1">
          <h2>لوگو فروشگاه</h2>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group
              label="پیوست عکس"
              label-for="mc-country"
          >
            <b-form-file
                accept=".jpg, .png, .gif"
                placeholder="انتخاب عکس"
                @input="makeBase64Pic"
                ref="refInputEl"
                drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <b-col v-if="imgUrl!==''" cols="12" class="position-relative">
          <!--          <feather-icon @click="removeLogo" icon="TrashIcon" class="text-danger cursor-pointer position-absolute top-0 m-1"-->
          <!--                        style="z-index: 100000" size="20"></feather-icon>-->
          <b-avatar
              size="200"
              rounded
              :src="imgUrl"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col cols="12" class="my-1">
          <h2>بنرهای فروشگاه</h2>
        </b-col>
        <b-col cols="12" md="4">
          <b-row>
            <b-col cols="12">

              <b-form-group
                  label="پیوست عکس"
                  label-for="mc-country"
              >
                <b-form-file
                    accept=".jpg, .png, .gif"
                    placeholder="انتخاب عکس"
                    @input="makeBase64PicBanner"
                    multiple
                    ref="refInputElBanner"
                    drop-placeholder="Drop file here..."
                />
              </b-form-group>
              <b-col v-if="banners.length > 0"
                     v-for="(item,idx) in banners" :key="idx" cols="12" class="position-relative m-25">
                <feather-icon @click="removeBlobBanner(idx)" icon="TrashIcon"
                              class="text-danger cursor-pointer position-absolute top-0 m-1"
                              style="z-index: 100000" size="20"></feather-icon>
                <img
                    class="w-100 rounded" style="height: 25rem"
                    :src="item.blob"
                />
              </b-col>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="8">
          <b-carousel
              id="carousel-example-generic"
              controls
              indicators
          >
            <b-carousel-slide
                :caption-html="`<b-button variant='primary' class='text-danger bg-white px-1'>برای حذف عکس کلیک کنید</b-button>`"
                v-for="(item,idx) in shopInfo.sliderFileData" :key="idx" style="height: 300px;"
                v-b-modal.modal-delete
                class="carouselItem card-img-overlay cursor-pointer" @click.native="setSelectedBanner(item)"
                :img-src="baseUrl+'media/gallery/vendorSlider/'+item.pictureUrl"/>
          </b-carousel>
        </b-col>
      </b-row>

    </b-card>
    <b-card>
      <b-row>
        <b-col class="my-1" cols="12">
          <h2>درباره ما</h2>
        </b-col>
        <b-col cols="12" md="12">
          <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent"></ckEditorMain>
        </b-col>
        <b-col class="my-3" cols="6">
          <b-button type="button" class="btn w-25" variant="primary" @click="updateProfile">ثبت</b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import {
  BAvatar, BBadge,
  BButton,
  BCard,
  BCol, BDropdown, BDropdownItem,
  BFormCheckbox,

  BFormFile,
  BFormGroup,
  BCarousel,
  BCarouselSlide,
  BFormInput, BFormSelect, BInputGroup, BLink,
  BMedia, BModal, BOverlay, BPagination,
  BRow,
  BTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import Helper from "@/libs/Helper";
import {UserGetVendorIndexDataRequest} from "@/libs/Api/Users";
import {getUserData} from "@/auth/utils";
import {VendorRequestUpdateFilesRequest} from "@/libs/Api/shopRequests";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {DeleteMediaByPicURL} from "@/libs/Api/portfolio";
import ckEditorMain from "@/views/components/ckEditorMain";


export default {
  components: {
    ckEditorMain,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  name: "ProfileInfo",
  title: "پروفایل",
  data() {
    return {
      showOverlay: false,
      logoPic: '',

      baseUrl: Helper.baseUrl,
      selectedBanner: null,
      shopInfo: [],
      imgUrl: '',
      rawHTML: '',
      banners:[],
    }
  },
  async created() {
    await this.getVendorData(10)
  },
  methods: {
    setEditorContent(content){
      this.shopInfo.description = content
    },
    async getVendorData(count) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        userName: getUserData().userName,
        count: count,
      };

      let userGetVendorIndexDataRequest = new UserGetVendorIndexDataRequest(_this);
      userGetVendorIndexDataRequest.setParams(data);
      await userGetVendorIndexDataRequest.fetch(function (content) {
        if (content.isSuccess) {
          _this.shopInfo = content.data.shopData;
          _this.imgUrl = _this.baseUrl+'media/gallery/vendorLogo/' + _this.shopInfo.logoFileData;
          console.log(content)
        }
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.$router.back();
        _this.showOverlay = false;
      })
    },
    async updateProfile() {
      let _this = this;
      _this.showOverlay = true;
      _this.$refs.editorContent.emitDataToParent()
      _this.shopInfo.logoFileData = _this.logoPic;
      _this.banners.forEach((item) => {
        delete item.blob
      })
      _this.shopInfo.sliderFileData = _this.banners;

      let vendorRequestUpdateRequest = new VendorRequestUpdateFilesRequest(_this);
      vendorRequestUpdateRequest.setParams(_this.shopInfo);
      await vendorRequestUpdateRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تغییرات اعمال شد.`,
          },
        })
        _this.banners = [];
        _this.showOverlay = false;
        _this.getVendorData(10);
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    async deleteMedia() {
      let _this = this;
      _this.showOverlay = true;

      let data = [];
      data.push(_this.selectedBanner)

      let deleteMediaByPicURL = new DeleteMediaByPicURL(_this);
      deleteMediaByPicURL.setParams(data);
      await deleteMediaByPicURL.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تصویر مورد نظر حذف شد`,
          },
        })
        _this.setSelectedBanner = null;
        _this.showOverlay = false
        _this.getVendorData(10)
      }, function (error) {
        _this.showOverlay = false
        console.log(error)
      })
    },
    setSelectedBanner(item) {
      this.selectedBanner = item.pictureUrl;
    },
    removeBanner(idx) {
      this.shopInfo.sliderFileData.splice(idx, 1)
    },
    removeBlobBanner(idx) {
      this.banners.splice(idx, 1)
    },
    removeLogo() {
      this.imgUrl = ''
      this.shopInfo.logoFileData = ''
    },
    async makeBase64PicBanner() {
      const that = this
      const f = []
      let tmpImage = {
        blob: '',
        base64: '',
        pictureUrl: '',
        priority: 1
      }

      Array.prototype.forEach.call(this.$refs.refInputElBanner.files, (element) => {
        if (element.size > 512000) {
          this.$toast.error('اندازه عکس نمیتواند بیشتر از 512 کیلوبایت باشد')
        } else {
          f.push(element)
        }
      })
      f.forEach((element) => {
        const reader = new FileReader()
        reader.onload = (function (theFile) {
          return function () {
            let binaryData = reader.result
            tmpImage.base64 = window.btoa(binaryData)
            tmpImage.blob = URL.createObjectURL(element)
            const clone = {...tmpImage}
            that.banners.push(clone)
            tmpImage.blob = ''
            tmpImage.priority = 2
            tmpImage.base64 = ''
          }
        })(f)
        reader.readAsBinaryString(element)
      })
      this.$refs.refInputElBanner.reset()
    },
    async makeBase64Pic() {
      const that = this
      const f = that.$refs.refInputEl.files[0]
      this.imgUrl = URL.createObjectURL(f)
      const reader = new FileReader()
      reader.onload = (function (theFile) {
        return function () {
          const binaryData = reader.result
          that.logoPic = window.btoa(binaryData)
        }
      })(f)
      reader.readAsBinaryString(f)
      this.$refs.refInputEl.reset()
    },
    customButtonClick() {
      this.shopInfo.description += this.rawHTML
      this.rawHTML = ''
    },
  }
}
</script>

<style scoped>

</style>
